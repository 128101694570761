module.exports={
  "settings.video.quality": "Videoqualität",
  "settings.audio.quality": "Audioqualität",
  "settings.audio.track": "Audiospur",
  "speed": "Geschwindigkeit",
  "play": "Abspielen",
  "pause": "Pause",
  "playPause": "Abspielen/Pause",
  "open": "öffnen",
  "close": "Schließen",
  "settings.audio.mute": "Stummschaltung",
  "settings.audio.volume": "Lautstärke",
  "pictureInPicture": "Bild im Bild",
  "appleAirplay": "Apple AirPlay",
  "googleCast": "Google Cast",
  "vr": "VR",
  "settings": "Einstellungen",
  "fullscreen": "Vollbild",
  "off": "aus",
  "settings.subtitles": "Untertitel",
  "settings.subtitles.font.size": "Größe",
  "settings.subtitles.font.family": "Schriftart",
  "settings.subtitles.font.color": "Farbe",
  "settings.subtitles.font.opacity": "Deckkraft",
  "settings.subtitles.characterEdge": "Ränder",
  "settings.subtitles.background.color": "Hintergrundfarbe",
  "settings.subtitles.background.opacity": "Hintergrunddeckkraft",
  "settings.subtitles.window.color": "Hintergrundfarbe",
  "settings.subtitles.window.opacity": "Hintergrunddeckkraft",
  "settings.time.hours": "Stunden",
  "settings.time.minutes": "Minuten",
  "settings.time.seconds": "Sekunden",
  "back": "Zurück",
  "reset": "Zurücksetzen",
  "replay": "Wiederholen",
  "ads.remainingTime": "Diese Anzeige endet in {remainingTime} Sekunden",
  "default": "standard",
  "colors.white": "weiß",
  "colors.black": "schwarz",
  "colors.red": "rot",
  "colors.green": "grün",
  "colors.blue": "blau",
  "colors.yellow": "gelb",
  "subtitle.example": "Beispiel Untertitel",
  "subtitle.select": "Untertitel auswählen",
  "playingOn": "Spielt auf <strong>{castDeviceName}</strong>",
  "connectingTo": "Verbindung mit <strong>{castDeviceName}</strong> wird hergestellt...",
  "watermarkLink": "Link zum Homepage",
  "controlBar": "Videoplayer Kontrollen",
  "player": "Video player",
  "seekBar": "Video-Timeline",
  "seekBar.value": "Wert",
  "seekBar.timeshift": "Timeshift",
  "seekBar.durationText": "aus",
  "quickseek.forward": "Vor",
  "quickseek.rewind": "Zurück",
  "ecoMode": "ecoMode",
  "ecoMode.title":"Eco Mode"
}
