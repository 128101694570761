module.exports={
  "settings.video.quality": "Videokwaliteit",
  "settings.audio.quality": "Audiokwaliteit",
  "settings.audio.track": "Audiospoor",
  "settings.audio.mute": "Dempen",
  "settings.audio.volume": "Volume",
  "settings.subtitles.window.color": "Vensterkleur",
  "settings.subtitles.window.opacity": "Venster doorzichtigheid",
  "settings.subtitles": "Ondertiteling",
  "settings.subtitles.font.color": "Lettertype kleur",
  "settings.subtitles.font.opacity": "Lettertype doorzichtigheid",
  "settings.subtitles.background.color": "Achtergrondkleur",
  "settings.subtitles.background.opacity": "Achtergrond doorzichtigheid",
  "colors.white": "wit",
  "colors.black": "zwart",
  "colors.red": "rood",
  "colors.green": "groen",
  "colors.blue": "blauw",
  "colors.cyan": "cyaan",
  "colors.yellow": "geel",
  "colors.magenta": "magenta",
  "percent": "{value}%",
  "settings.subtitles.font.size": "Lettertype grootte",
  "settings.subtitles.characterEdge": "Lettertype rand",
  "settings.subtitles.characterEdge.raised": "verhoogd",
  "settings.subtitles.characterEdge.depressed": "verlaagd",
  "settings.subtitles.characterEdge.uniform": "uniform",
  "settings.subtitles.characterEdge.dropshadowed": "schaduw",
  "settings.subtitles.font.family": "Standaard lettertype",
  "settings.subtitles.font.family.monospacedserif": "monospace serif",
  "settings.subtitles.font.family.proportionalserif": "proportioneel serif",
  "settings.subtitles.font.family.monospacedsansserif": "monospace sans-serif",
  "settings.subtitles.font.family.proportionalsansserif": "proportioneel sans-serif",
  "settings.subtitles.font.family.casual": "casual",
  "settings.subtitles.font.family.cursive": "cursief",
  "settings.subtitles.font.family.smallcapital": "kleine hoofdletters",
  "settings.time.hours": "Uren",
  "settings.time.minutes": "Minuten",
  "settings.time.seconds": "Seconden",
  "ads.remainingTime": "Deze advertentie eindigt in {remainingTime} seconden.",
  "settings": "Instellingen",
  "fullscreen": "Volledig scherm",
  "speed": "Snelheid",
  "playPause": "Afspelen/Pauzeren",
  "play": "Afspelen",
  "pause": "Pauzeren",
  "open": "Openen",
  "close": "Sluiten",
  "pictureInPicture": "Picture-in-Picture",
  "appleAirplay": "Apple AirPlay",
  "googleCast": "Google Cast",
  "vr": "VR",
  "off": "uit",
  "auto": "automatisch",
  "ecoMode": "Eco-modus",
  "ecoMode.title": "Eco-modus",
  "back": "Terug",
  "reset": "Reset",
  "replay": "Opnieuw afspelen",
  "normal": "normaal",
  "default": "standaard",
  "live": "Live",
  "subtitle.example": "voorbeeld ondertiteling",
  "subtitle.select": "Selecteer ondertiteling",
  "playingOn": "Speelt af op <strong>{castDeviceName}</strong>",
  "connectingTo": "Verbinden met <strong>{castDeviceName}</strong>...",
  "watermarkLink": "Link naar homepage",
  "controlBar": "Videospeler bediening",
  "player": "Videospeler",
  "seekBar": "Video tijdlijn",
  "seekBar.value": "Waarde",
  "seekBar.timeshift": "Tijdverschuiving",
  "seekBar.durationText": "van",
  "quickseek.forward": "Vooruitspoelen",
  "quickseek.rewind": "Terugspoelen"
}