module.exports={
  "settings.video.quality": "Calidad de Video",
  "settings.audio.quality": "Calidad de Audio",
  "settings.audio.track": "Pista de Audio",
  "settings.audio.mute" : "Silencio",
  "settings.audio.volume" : "Volumen",
  "settings.subtitles.window.color" : "color de Ventana",
  "settings.subtitles.window.opacity" : "opacidad de Ventana",
  "settings.subtitles" : "Subtítulos",
  "settings.subtitles.font.color" : "color de Fuente",
  "settings.subtitles.font.opacity" : "opacidad de Fuente",
  "settings.subtitles.background.color" : "color de Fondo",
  "settings.subtitles.background.opacity": "opacidad de Fondo",
  "colors.white": "blanco",
  "colors.black": "negro",
  "colors.red": "rojo",
  "colors.green": "verde",
  "colors.blue": "azul",
  "colors.cyan": "cian",
  "colors.yellow": "amarillo",
  "colors.magenta": "magenta",
  "percent": "{value}%",
  "settings.subtitles.font.size": "tamaño de Fuente",
  "settings.subtitles.characterEdge": "borde del Caracter",
  "settings.subtitles.characterEdge.raised": "alzado",
  "settings.subtitles.characterEdge.depressed": "discreto",
  "settings.subtitles.characterEdge.uniform": "uniforme",
  "settings.subtitles.characterEdge.dropshadowed": "sombreado",
  "settings.subtitles.font.family": "tipo de Fuente",
  "settings.subtitles.font.family.monospacedserif": "monospaced serif",
  "settings.subtitles.font.family.proportionalserif": "proportional serif",
  "settings.subtitles.font.family.monospacedsansserif": "monospaced sans serif",
  "settings.subtitles.font.family.proportionalsansserif": "proportional sans serif",
  "settings.subtitles.font.family.casual": "casual",
  "settings.subtitles.font.family.cursive": "cursiva",
  "settings.subtitles.font.family.smallcapital": "small capital",
  "settings.time.hours": "Horas",
  "settings.time.minutes": "Minutos",
  "settings.time.seconds": "Segundos",
  "ads.remainingTime": "Este anuncio acabará en {remainingTime} segundos.",
  "settings": "Configuración",
  "fullscreen" : "Pantalla Completa",
  "speed": "Velocidad",
  "playPause" : "Reproducir/Pausa",
  "play": "Reproducir",
  "pause": "Pausa",
  "open": "Abrir",
  "close": "Cerrar",
  "pictureInPicture": "Imagen en Imagen",
  "appleAirplay" : "Apple AirPlay",
  "googleCast": "Google Cast",
  "vr" : "VR",
  "off": "off",
  "auto": "auto",
  "ecoMode": "ecoMode",
  "ecoMode.title": "Eco Mode",
  "back" : "Atrás",
  "reset": "Reiniciar",
  "replay": "Rebobinar",
  "normal": "normal",
  "default": "predeterminado",
  "live": "Directo",
  "subtitle.example": "Ejemplo de Subtítulo",
  "subtitle.select": "Seleccionar subtítulo",
  "playingOn": "Reproduciendo en <strong>{castDeviceName}</strong>",
  "connectingTo": "Conectando a <strong>{castDeviceName}</strong>...",
  "watermarkLink": "Enlace al inicio",
  "controlBar": "Controles del Reproductor",
  "player": "Reproductor de Video",
  "seekBar": "Línea de Tiempo",
  "seekBar.value": "posición",
  "seekBar.timeshift": "cambio de posición",
  "seekBar.durationText": "de",
  "quickseek.forward": "Adelantar",
  "quickseek.rewind": "Rebobinar"
}