module.exports={
  "settings.video.quality": "Video Quality",
  "settings.audio.quality": "Audio Quality",
  "settings.audio.track": "Audio Track",
  "settings.audio.mute" : "Mute",
  "settings.audio.volume" : "Volume",
  "settings.subtitles.window.color" : "Window color",
  "settings.subtitles.window.opacity" : "Window opacity",
  "settings.subtitles" : "Subtitles",
  "settings.subtitles.font.color" : "Font color",
  "settings.subtitles.font.opacity" : "Font opacity",
  "settings.subtitles.background.color" : "Background color",
  "settings.subtitles.background.opacity": "Background opacity",
  "colors.white": "white",
  "colors.black": "black",
  "colors.red": "red",
  "colors.green": "green",
  "colors.blue": "blue",
  "colors.cyan": "cyan",
  "colors.yellow": "yellow",
  "colors.magenta": "magenta",
  "percent": "{value}%",
  "settings.subtitles.font.size": "Font size",
  "settings.subtitles.characterEdge": "Character edge",
  "settings.subtitles.characterEdge.raised": "raised",
  "settings.subtitles.characterEdge.depressed": "depressed",
  "settings.subtitles.characterEdge.uniform": "uniform",
  "settings.subtitles.characterEdge.dropshadowed": "drop shadowed",
  "settings.subtitles.font.family": "Font family",
  "settings.subtitles.font.family.monospacedserif": "monospaced serif",
  "settings.subtitles.font.family.proportionalserif": "proportional serif",
  "settings.subtitles.font.family.monospacedsansserif": "monospaced sans serif",
  "settings.subtitles.font.family.proportionalsansserif": "proportional sans serif",
  "settings.subtitles.font.family.casual": "casual",
  "settings.subtitles.font.family.cursive": "cursive",
  "settings.subtitles.font.family.smallcapital": "small capital",
  "settings.time.hours": "Hours",
  "settings.time.minutes": "Minutes",
  "settings.time.seconds": "Seconds",
  "ads.remainingTime": "This ad will end in {remainingTime} seconds.",
  "settings": "Settings",
  "fullscreen" : "Fullscreen",
  "speed": "Speed",
  "playPause" : "Play/Pause",
  "play": "Play",
  "pause": "Pause",
  "open": "open",
  "close": "Close",
  "pictureInPicture": "Picture-in-Picture",
  "appleAirplay" : "Apple AirPlay",
  "googleCast": "Google Cast",
  "vr" : "VR",
  "off": "off",
  "auto": "auto",
  "ecoMode": "ecoMode",
  "ecoMode.title":"Eco Mode",
  "back" : "Back",
  "reset": "Reset",
  "replay": "Replay",
  "normal": "normal",
  "default": "default",
  "live": "Live",
  "subtitle.example": "example subtitle",
  "subtitle.select": "Select subtitle",
  "playingOn": "Playing on <strong>{castDeviceName}</strong>",
  "connectingTo": "Connecting to <strong>{castDeviceName}</strong>...",
  "watermarkLink": "Link to Homepage",
  "controlBar": "Video player controls",
  "player": "Video player",
  "seekBar": "Video timeline",
  "seekBar.value": "Value",
  "seekBar.timeshift": "Timeshift",
  "seekBar.durationText": "out of",
  "quickseek.forward": "Fast Forward",
  "quickseek.rewind": "Rewind"
}
